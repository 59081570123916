.Home {
    width: 100dvw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.HomeHeader {
    width: 80%;
    max-width: 1200px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    background-color: rgba(255, 255, 255, 0.893);
    backdrop-filter: blur(0.7vh);
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
    padding: 2vh 5vw;
    border-radius: 1vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
    z-index: 10000;
    margin-top: 2vw;
}

.Button {
    width: 30vw;
    padding-top: 1.3vh;
    padding-bottom: 1.3vh;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
    color: #184937;
    font-size: 2.4vh;
    border-radius: 1vw;

    &:hover {
        background-color: #209f44;
        color: #ffff;
    }
}

.HomeHeader img {
    width: 20vh;
}

.HomeHeader .UsefullLinks {
    width: 40%;
    display: flex;
    justify-content: space-around;
}

.UsefullLinks a {
    text-decoration: none;
    color: #1f1f1f;
    cursor: pointer;
}

.HamburgerMenu {
    display: none;
    cursor: pointer;
}

.SideMenu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
    display: flex;
    flex-direction: column;
    padding: 2vh;
    z-index: 10001;
}

.SideMenu a {
    text-decoration: none;
    color: #1f1f1f;
    padding: 1vh 0;
    font-size: 1.2rem;
}

.CloseMenu {
    background: none;
    border: none;
    font-size: 1.5rem;
    align-self: flex-end;
    cursor: pointer;
}

.Banner {
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    background: rgb(2, 80, 49);
    background: -moz-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: -webkit-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#025031", endColorstr="#059846", GradientType=1);
}

.BannerOverImage {
    position: absolute;
    height: 80vh;
    width: 100vw;
    object-fit: cover;
    overflow: hidden;
}

.Contents {
    position: relative;
    width: 90%;
    top: 5vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Texts {
    max-width: 50vw;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 25vh;
    justify-content: space-around;
    gap: 3vh;
}

.Texts .Title {
    font-family: Impact;
    font-size: 5vw;
    line-height: 11vh;
    font-family: inter, Arial !important;
    font-weight: 900;
    line-height: 9.7vh !important;
}

.Texts .SubTitle {
    max-width: 40vw;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 3vh;
}

.ValuePro img {
    width: 25vw;
}

.Solucoes-vizzion {
    width: 90vw;
    margin: 0 auto;
    margin-top: 6vh;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
}

.Solucoes-title {
    display: flex;
    gap: 1vw;
    align-items: center;
    font-weight: 750;
}

.Solucoes-title img {
    height: 4.2vh;
}

.Description {
    max-width: 40vw;
}

.ChegaDeAchismos {
    width: 100vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    background: rgb(2, 80, 49);
    background: -moz-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: -webkit-linear-gradient(128deg, rgba(23, 144, 77, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#025031", endColorstr="#059846", GradientType=1);
}

.chegaDeAchismosButton {
    display: flex !important;
    margin: 0 auto !important;
    padding-left: 10vw;
    padding-right: 10vw;
    width: unset !important;
    margin-top: 5vh !important;
}

.text-container {
    display: flex;
    justify-content: space-between;
}

.Demandas img {
    width: 30vw;
}

.Cards {
    width: 56vw;
    position: relative;
    top: -33vh;
    display: flex;
    justify-content: space-between;
}

.TechSection {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;

    padding-top: 6vh;
    padding-bottom: 6vh;
    gap: 2vh;
}

.Como-funciona-tech {
    width: 90vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.TechTitle {
    text-transform: uppercase;
    font-weight: 750;
    line-height: 1.2;
}

.Images img {
    width: 30vw;
    max-width: 100%;
}

.iframe-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.fontDesktopOnly{
    font-size: 1.1vw;
}

iframe {
    width: 100%;
    height: 40vh;
    max-width: 60vw;
    border-radius: 1vw;
    border: none;
}

.MobileOnly {
    display: none;
}

.SintaxeEspacial {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
    align-items: center;
    margin-bottom: 5vh;
}

.Sintaxe-Texts {
    max-width: 45vw;
    text-align: justify;
}

.Sintaxe-Texts p {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.Sintaxe-Texts h1 {
    line-height: 4vh;

    &::after {
        margin-top: 1.5vh;
        position: relative;
        display: flex;
        content: "";
        width: 25vw;
        height: 0.7vh;
        background: linear-gradient(45deg,
                #00ff00,
                #ffff00,
                #ff0000);
        border-radius: 1vh;
        margin-bottom: 4vh;
    }
}

.Bold {
    font-weight: bold;
}

.Footer {
    width: 100vw;
    height: 80vh;
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8vh;
}
.Footer h3{
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}
.social-media {
    display: flex;
    gap: 2vw;
}
.StartupAssociada{
    width: 90vw;
    display: flex;
    margin: 0 auto;
}

.socialeContato {
    margin: 0 auto;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Button-Footer {
    text-decoration: none;
    color: #202020;
    width: 40vw;
    background-color: #6eeb20;
    text-align: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-radius: 1vw;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    &:hover{
        background-color: #fff;
    }
}



@media screen and (max-width: 790px) {

    .Title{
        font-size: 12vw !important;
        margin-top: 2vh;
    }

    .socialeContato {
        flex-direction: column-reverse;
        gap: 4vh;
    }
    .social-media{
        gap: 6vw;
    }

    .SintaxeEspacial {
        flex-direction: column;
        gap: 4vh;
    }

    .Imagens-Sintaxe img {
        width: 90vw;
        height: auto;
        object-fit: cover;
    }

    .Sintaxe-Texts {
        max-width: 90vw;
    }

    .Sintaxe-Texts h1 {
        text-align: center;
        font-size: 6vw;
        margin-top: 1vh;

        &::after {
            width: 80vw;
            height: 0.5vh;
            margin: 0 auto;
            margin-top: 3vh;
            margin-bottom: 5vh;
        }
    }

    .Sintaxe-Texts {
        font-size: 4vw;
    }

    .CorrectMobile {
        font-size: 9vw !important;
    }

    .TechSection {
        width: 95vw;
        flex-direction: column;
    }

    .MobileOnly {
        display: flex;
        width: 90vw;
        object-fit: cover;
        overflow: hidden;
        height: auto;
    }

    .desktopOnly {
        display: none !important;
    }

    .TechTitle {
        font-size: 7vw;
        text-align: center;
        line-height: 4.3vh;
    }

    .MobileDesc {
        display: flex;
        margin: 0 auto;
        width: 80vw;
        font-size: 4vw;
        text-align: justify;
        margin-top: 2vh;
    }

    iframe {
        max-width: 100%;
        height: 23vh;
    }

    .iframe-container p {
        font-size: 3.5vw;
    }

    .Images img {
        width: 90vw;
    }

    .HomeHeader {
        width: 85% !important;
        padding: 2vh 2vw !important;
        margin-top: 5vw;
    }

    .HomeHeader .UsefullLinks {
        display: none !important;
    }

    .HamburgerMenu {
        display: block;
    }

    .Button {
        width: 75vw;
    }

    .Banner {
        height: 130vh;
    }

    .BannerOverImage {
        height: 130vh;
    }

    .Contents {
        flex-direction: column !important;
        height: 128vh;
    }

    .Texts {
        max-width: 100% !important;
        color: #fff;
        position: relative;
        top: 22vh;
    }

    .Texts .Title {
        font-family: Impact;
        font-size: 15vw;
        line-height: 8.5vh !important;
    }

    .Texts .SubTitle {
        max-width: 100%;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 3vh;
        font-size: 5vw;
    }

    .ValuePro img {
        position: relative;
        width: 90vw;
        bottom: 7vh;
    }

    .Solucoes-vizzion {
        position: relative;
        max-height: unset;
    }

    .Solucoes-title {
        font-size: 3.8vh;
        font-weight: 800;
    }

    .Solucoes-title img {
        height: 4.2vh;
    }

    .Description {
        text-align: justify;
        font-size: 4vw;
        max-width: 90vw;
    }

    .ChegaDeAchismos h1 {
        font-size: 15vw;
        word-wrap: normal;
        line-height: 8vh;
    }

    .ChegaDeAchismos p {
        margin: 0 auto;
        width: 70vw;
        text-align: justify;
        font-size: 4vw;
        margin-top: 2vh;
    }

    .chegaDeAchismosButton {
        display: flex !important;
        margin: 0 auto !important;
        padding-left: 10vw;
        padding-right: 10vw;
        width: unset !important;
        margin-top: 5vh !important;
    }

    .text-container {
        flex-direction: column;
        gap: 4vh;
    }

    .Demandas img {
        width: 80vw;
    }

    .Cards {
        top: 0;
        flex-direction: column;
        gap: 2vh;
    }
}

/* COMPONENTE  */
.Card-solucao {
    display: flex;
    flex-direction: column;
}

.Body-card-solucao {
    width: 25vw;
    height: 22vh;
    border-radius: 1vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
    position: relative;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.texto-superior {
    width: 14vw;
    box-sizing: border-box;
    padding: 2vh;
    padding-top: 0.7vh;
    background-color: #209f44;
    text-align: center;
    position: relative;
    bottom: -1.3vh;
    left: 0.4vw;
    border-radius: 0.4vw;
    color: #fff;
}

.card-titulo-e-icone {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Titulo {
    color: #262626;
}

.card-titulo-e-icone img {
    width: 4vw;
}

.VerMais {
    text-decoration: none;
    background-color: #209f44;
    max-width: 7vw;
    text-align: center;
    position: relative;
    left: 60%;
    color: #fff;
    padding: 0.5vw 2vh;
    border-radius: 0.4vw;
    top: -1.5vh;

    &:hover {
        background-color: #184937;
    }
}

@media screen and (max-width: 790px) {
    .Body-card-solucao {
        width: 80vw;
        border-radius: 1.5vw;
        flex-direction: column;
        padding: 4vw;
    }

    .texto-superior {
        width: 40vw;
        border-radius: 1vw;
        left: 1vw;
    }

    .card-solucao-descricao {
        text-align: justify;
        font-size: 3.5vw;
        margin-top: 1.5vh;
    }

    .card-titulo-e-icone img {
        width: 8vw;
    }

    .VerMais {
        max-width: 16vw;
        padding: 1vh 2.5vh;
        border-radius: 1vw;
    }
}