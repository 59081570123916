.PaginaDeNovoUsuario{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}
.Body-paginaDeNovoUsuario{
    width: 74vw;
    display: flex;
    flex-direction: column;
    padding: 2vw;
}

.NovoUsuarioForm form{
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin: 0 auto;
}
.form-controll-novo-usuario{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    padding: 1vw;
}
.form-controll-novo-usuario label{
    font-weight: bold;
    &::after{
        content: '';
        display: flex;
        width: 5vw;
        height: 4px;
        border-radius: 0.7vh;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
        background-color: #2dc358;
    }
}
.form-controll-novo-usuario input{
    padding: 1vh;
    border: none;
    border-radius: 0.7vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.submitNovoUsuario{
    width: 20vw;
    background-color: #00b348;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content:center;
    margin: 0 auto;
    margin-top: 1.5vh;
}

.submitNovoUsuario:hover{
    background-color: #222222;
}
.submitNovoUsuario {
    padding: 1vh;
    border: none;
    border-radius: 0.7vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}