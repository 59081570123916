.SolicitacaoRelatorioPage {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.BodyNewReport {
    width: 74%;
    padding: 4vh;
}

.HeaderNewReport {
    position: relative;
    width: 100%;
}

.Titulo {
    margin-bottom: 1vh;

    &::after {
        content: "";
        display: flex;
        width: 20vh;
        height: 5px;
        background-color: rgb(17, 149, 53);
        border-radius: 1vh;
        margin-top: 1vh;
    }
}

.infoHeader {
    display: flex;
    position: relative;
    width: 95%;
    text-align: justify;
    box-sizing: border-box;
    position: relative;
    top: 2vh;
    border-radius: 0.8vh;
    color: #25512f;
}


.WassupAIBox {
    position: relative;
    z-index: 1000;
    top: 2vh;
    left: 0vh;
    width: 95vh;
    display: flex;
    flex-direction: column;
}

.RoundCircleWithZZ {
    width: 8vh;
    height: 8vh;
    position: relative;
    z-index: 100;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 100vh;
    font-family: inter, Arial;
    background-color: #2cd048;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
    color: #fefefe;
}

.FormChatT {
    width: 100%;
    position: absolute;
    top: 3vh;
    left: 2.5vh;
    z-index: 00;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    background-color: #faf7f7d6;
    backdrop-filter: blur(0.7vh);
    padding: 2vh 2vh 2vh 7vh;
    border-radius: 2vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.WassupResponseAsMessage {
    font-size: 12px;
    max-height: 20vh;
    overflow-y: scroll;
    text-align: justify;
    display: flex;
    gap: 2vh;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.Close {
    display: flex;
    width: 4vh;
    height: 4vh;
    border-radius: 0.7vh;
    color: #ffff;
    position: relative;
    right: 0 !important;
    justify-content: center;
    padding: 1vh;
    background-color: #b31f1f;
    cursor: pointer;
}

.WassupFormController {
    margin-top: 2vh;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.WassupLabel {
    background-color: #59F873;
    width: 8vh !important;
    padding: 1.3vh 1.5vh;
    display: flex;
    justify-content: center;
    border-radius: 0vh 0.7vh 0.7vh 0vh;
    cursor: pointer;
}

.WassupLabel .ImagemDeIcone {
    height: 2vh;
    box-sizing: border-box;
}

.WassupRequest {
    display: none;
}

.InputDeTextoWassup {
    width: 100% !important;
    word-wrap: normal;
    overflow-y: scroll;
    border: none;
    border-radius: 1vh 0vh 0vh 1vh;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.LoadingSpinner {
    border: 6px solid rgba(17, 151, 75, 0.1);
    border-left-color: #2d7b31;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: auto;
    display: block;
}

.IsNewRealState {
    width: 100%;
    min-height: 75vh;
    position: relative;
    display: flex;
    justify-content: space-around;
    top: 3vh;
}

.Terrain {
    position: relative;
    top: 20vh;
    width: 30vh;
    height: 30vh;
    border: none;
    border-radius: 1vh;
    background-color: #2cd048;
    color: #2b2b2b;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2ch;
    padding: 2vh;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: #2b2b2b;
        color: #fff;
    }
}

.NewReal {
    position: relative;
    top: 20vh;
    width: 30vh;
    height: 30vh;
    background-color: #2b2b2b;
    border-radius: 1vh;
    border: none;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2ch;
    padding: 2vh;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: #2cd048;
        color: #2b2b2b;
    }
}

.BodyDadosDoImovel {
    position: relative;
    top: -1vh;
    display: flex;
    flex-direction: column;
    height: 89vh;
    gap: 2vh;
    justify-content: space-between !important;
}

.formControll {
    width: 100%;
}

.DescricaoLabel {
    max-width: 80vh;
    position: relative;
    left: 4%;
    display: flex;
    gap: 2vh;
    align-items: center;
    text-transform: uppercase;
    color: #1d1d1d !important;
}

.Dta-img {
    width: 2vh;
}

.Descricao {
    text-align: justify;
    position: relative;
    width: 90%;
    height: 15vh;
    background-color: #ffffff;
    padding: 1vh;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 0 auto;
    border-radius: 1vh;
}

.checkboxStyle {
    width: 40vh;
    background-color: #19d138;
    border-radius: 0.6vh;
    padding: 0vh 8vh;
    display: flex;
    gap: 4vh;
    flex-direction: row-reverse;
    align-items: center;
}

.checkboxStyle input[type="checkbox"] {
    cursor: pointer;
}

.checkboxStyle label {
    font-size: 16px;
}

.checkboxStyle input[type="checkbox"]:checked {
    background-color: lightgreen;
    border-color: lightgreen;
}

.Matricula {
    margin: 0 auto;
    max-width: 92% !important;
}

.ButtonAdvance {
    position: relative;
    width: 20vh;
    height: 5vh;
    border: none;
    text-align: center;
    align-items: center;
    background-color: #19d138;
    font-size: 1.7vh;
    top: -10vh;
    border-radius: 1vh;
    cursor: pointer;

    &:hover{
        background-color: #155f32;
        color: #fff;
    }
}

.matriculaForm input {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.matriculaForm label {
    position: relative;
    display: flex;
    gap: 2vh;
    align-items: center;
    text-transform: uppercase;
    color: #1d1d1d !important;
}

.SellData {
    margin: 0 auto;
    max-width: 90%;
    display: flex;
    gap: 5vh;
    justify-content: space-between !important;
}

.SellData input {
    width: 20vh;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.ConfiguracoesEmpreendimentos {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 5vh;
    justify-content: space-between;
}

.ConfiguracoesEmpreendimentos input {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.tipoEmpreendimento {
    input {
        width: 30vh;
    }
}

.QueroEncontrarOImovel {
    position: relative;
    top: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55vh;
}

.metragemUnidade {
    label {
        display: flex;
        align-items: center;
        color: #1d1d1d;
    }

    input {
        width: 100%;
    }
}

.tamanhoDoTerrenoForm {
    label {
        display: flex;
        align-items: center;
        color: #1d1d1d;
    }

    input {
        width: 100%;
    }
}

.tamanhoDoTerrenoForm {
    max-width: 50vh;
}

.metragemUnidade {
    max-width: 50vh;
}

.unidadesController {
    label {
        display: flex;
        align-items: center;
        color: #1d1d1d;
    }

    input {
        width: 100%;
    }
}

.unidadesController {
    max-width: 50vh;
}

.tipoEmpreendimento {
    label {
        display: flex;
        align-items: center;
        color: #1d1d1d;
    }

    input {
        width: 100%;
    }
}

.tipoEmpreendimento {
    max-width: 50vh;
}

.ButtonSendSolicitacao{
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 2vh 10vh;
}

.tituloProjeto{
    label{
        max-width: 80vh;
        position: relative;
        left: 4%;
        display: flex;
        gap: 2vh;
        align-items: center;
        text-transform: uppercase;
        color: #1d1d1d !important;
    }
    input{
        max-width: 80vh;
        position: relative;
        left: 4%;
    }
}

.dadosIniciaisGerais{
    display: flex;
    width: 90% !important;
    margin: 0 auto;
    border: 1px solid black;
    padding: 2.7vw 3.5vw  2vw 3.5vw;
    border-radius: 1vh;
}
.InputContainer{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
.DocumentosDoProprietario{
    display: flex;
    flex-direction: column;
}

.seImovelProprio {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.seImovelProprio input[type="checkbox"] {
    display: none;
}

.seImovelProprio label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: #333;
}

.seImovelProprio label::before {
    content: "";
    width: 46px !important;
    height: 46px !important;
    border: 2px solid #666;
    border-radius: 4px; 
    background: #fff;
    display: inline-block;
    transition: all 0.3s ease;
}

.seImovelProprio input[type="checkbox"]:checked + label::before {
    background: #4caf50; 
    border-color: #4caf50;
    display: flex;
    justify-content: center;
    align-items: center;
}

.seImovelProprio input[type="checkbox"]:checked + label::after {
    content: "sim";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    font-size: 12px;
    font-weight: bold;
    left: 7.7%;
}

.InputContainer input[type="text"]{
    padding: 1vh 1vw;
    min-width: 15vw;
    width: 100%;
    border: 1px solid black;
    border-radius: 0.5vh;
}
.InputContainer label{
    display: flex;
    gap: 1vh;
}
.InputContainer label img{
    width: 2vh;   
}
.DadosGerais{
    display: flex;
    padding: 0.5vw 3.5vw;
    background-color: #4bec66;
    font-weight: 500;
    border-radius: 0.7vh;
    max-width: 10vw;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    text-align: center;
    justify-content: center;
    position: relative;
    left: 35%;
    bottom: -8%;
}
.holderSec{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: -1vh;
}
.CheckboxDocHolder {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto;
    gap: 1vw;
    align-items: center;
    padding: 1vh 0vh;
}
.CheckboxDocHolder input[type="checkbox"] {
    display: none;
}
.CheckboxDocHolder label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: #333;
}
.CheckboxDocHolder label::before {
    content: "";
    width: 40px;
    height: 20px;
    border: 2px solid #666;
    border-radius: 4px;
    background: #fff;
    display: inline-block;
    transition: all 0.3s ease;
}
.CheckboxDocHolder input[type="checkbox"]:checked + label::before {
    background: #4caf50;
    border-color: #4caf50;
}
.CheckboxDocHolder label::after {
    content: "";
    position: absolute;
    color: white;
    font-size: 12px;
    font-weight: bold;
    left: 7.7%;
}
.CheckboxDocHolder input[type="checkbox"]:checked + label::after {
    content: "sim";
    display: flex;
    justify-content: center;
    align-items: center;
}
.CheckboxDocHolder input[type="text"] {
    min-width: 15vw;
    width: 100%;
    border: 1px solid black;
    border-radius: 0.5vh;
}
.CheckboxDocHolder{
    position: relative;
    bottom: -0.7vh;
}
.holderMapDescSec{
    width: 70vw;
    display: flex;
    justify-content: space-between;
    height: 70%;
}
.DescricaoDoImovel {
    width: 50%;
}
.DescricaoDoImovelTexteArea{
    width: 33vw;
}
.DescricaoDoImovelTexteArea textarea{
    width: 93%;
    padding: 1vw;
    border-radius: 1vh;
}
.seDeisponivelPraVenda input[type="checkbox"]:checked + label::after{
    content: "sim";
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0.456vw;
}
.seDeisponivelPraVenda label::before{
    width: 30px !important;
    height: 30px !important;
}
.SeDisponivelPraVendaHolder{
    display: flex;
    position: relative;
    top: 1.5vh;
    flex-direction: column;
    gap: 1vh;
}
.FlexDetailedSecion{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.holderSellingData{
    display: flex;
    flex-direction: column;
    gap: 3.5vh;
    position: relative;
    top: 1.5vh;
}
.holderSellingData input{
    width: 15vw;
    padding: 1vh;
}
.SeDisponivelPraVendaContainer{
    width: 15vw;
}
.seDeisponivelPraVendaContainer label{
    display: flex;
    align-items: baseline;
}
.SeDisponivelPraVendaContainer label img{
    object-fit: contain;
}
.info-map-holder{
    width: 30vw;
    padding: 1vh 2.5vh;
    background-color: #9beb9e;
    border-radius: 1vh;
    color: #0d4925;
}