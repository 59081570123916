/* Estilo do container */
.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(2, 80, 49);
    background: -moz-linear-gradient(
      128deg,
      rgba(2, 80, 49, 1) 2%,
      rgba(23, 144, 77, 1) 16%,
      rgba(16, 121, 84, 1) 28%,
      rgba(16, 121, 84, 1) 29%,
      rgba(13, 92, 64, 1) 39%,
      rgba(4, 75, 53, 1) 70%,
      rgba(5, 152, 70, 1) 100%
    );
    background: -webkit-linear-gradient(
      128deg,
      rgba(2, 80, 49, 1) 2%,
      rgba(23, 144, 77, 1) 16%,
      rgba(16, 121, 84, 1) 28%,
      rgba(16, 121, 84, 1) 29%,
      rgba(13, 92, 64, 1) 39%,
      rgba(4, 75, 53, 1) 70%,
      rgba(5, 152, 70, 1) 100%
    );
    background: linear-gradient(
      128deg,
      rgba(2, 80, 49, 1) 2%,
      rgba(23, 144, 77, 1) 16%,
      rgba(16, 121, 84, 1) 28%,
      rgba(16, 121, 84, 1) 29%,
      rgba(13, 92, 64, 1) 39%,
      rgba(4, 75, 53, 1) 70%,
      rgba(5, 152, 70, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#025031",
      endColorstr="#059846",
      GradientType=1
    );
    z-index: 1000;
  }
  
  /* Estilo do círculo de loading */
  .loading-circle {
    border: 3vh solid rgba(255, 255, 255, 0.3);
    border-top: 3vh solid #fff;
    border-radius: 50%;
    width: 5vw;
    height: 5vw;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  /* Animação de rotação do círculo */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Estilo do texto */
  .loading-text {
    color: #fff;
    font-size: 2.5rem;
    text-align: center;
    max-width: 50vw;
    text-align: center;
    animation: fadeIn 2s ease-in-out infinite;
  }
  
  /* Animação de fade-in para o texto */
  @keyframes fadeIn {
    0%,
    100% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
  }
  