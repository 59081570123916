.Body-vzdn {
    width: 100vw;
    display: flex;
}

.side-bar-vzdna {
    max-width: 23vw;
    width: 100%;
    height: 100%;
    min-height: 92vh;
    max-height: 92vh !important;
    display: flex;
    flex-direction: column;
    background: rgb(4, 25, 11);
    background: -moz-linear-gradient(150deg, rgba(4, 25, 11, 1) 0%, rgba(4, 56, 29, 1) 5%, rgba(24, 92, 63, 1) 34%, rgba(16, 79, 30, 1) 63%, rgba(13, 79, 70, 1) 100%);
    background: -webkit-linear-gradient(150deg, rgba(4, 25, 11, 1) 0%, rgba(4, 56, 29, 1) 5%, rgba(24, 92, 63, 1) 34%, rgba(16, 79, 30, 1) 63%, rgba(13, 79, 70, 1) 100%);
    background: linear-gradient(150deg, rgba(4, 25, 11, 1) 0%, rgba(4, 56, 29, 1) 5%, rgba(24, 92, 63, 1) 34%, rgba(16, 79, 30, 1) 63%, rgba(13, 79, 70, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#04190b", endColorstr="#0d4f46", GradientType=1);
}

.side-bar-vzdna a {
    text-decoration: none;
    color: #ffff;
    display: flex;
    align-items: center;
    gap: 2vw;
    padding: 1vh 1.3vh;
    border: 1px solid #fffbfb;
    backdrop-filter: blur(0.2vh);
    border-radius: 1vh;
}

.Links-uteis-vzdna {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.Links-uteis-vzdna a:hover {
    background-color: #49d454;
}

.Acess {
    display: flex;
    align-items: center;
}

.icone-vzdna {
    width: 2vw;
}

.backgroundImgSideBar {
    position: absolute;
    z-index: 00;
    height: 100%;
    min-height: 92vh;
    max-height: 92vh !important;
    object-fit: cover;
    overflow: hidden;
    max-width: 23vw;
    opacity: 0.2;
}

.Contents-vzdna {
    position: relative;
    z-index: 1000;
    height: 90vh;
    padding: 2vh 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.content-body-vzdna {
    width: 100%;
}

.Profile-vzdna {
    display: flex;
    align-items: center;
    gap: 1vw;
    justify-content: center;
    align-items: center;
}

.side-bar-vzdna {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PopupProfile-vzdna {
    width: 23vw;
    height: 23vh;
    padding: 2vh;
    background-color: #19c725c7;
    backdrop-filter: blur(0.5vh);
    border-radius: 1vw 1vw 1vw 0vw;
    position: absolute;
    z-index: 5200;
    right: 4vw;
}

.listOfProfileOptions-vzdna {
    list-style-type: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.itemPopup-vzdna a {
    border: none;
}

.itemPopup-vzdna a:hover {
    background-color: unset;
}

.Indicadores-vzdna h1 {
    color: #ffff;
    line-height: 4vh;
}

.Indicadores-vzdna h1::after {
    content: '';
    display: flex;
    background-color: #f3f1f1;
    width: 5vw;
    height: 3px;
    border-radius: 1vh;
    margin-top: 2vh;
}

.report-page-body-vzdna {
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.imgs-imovel-vzdna {
    display: flex;
    flex-direction: column;
}

.Imagens-imovel {
    position: relative;
    top: 5vh;
    display: flex;
    width: 95%;
    margin: 0 auto;
    height: 55vh;
}

.Imagem1km {
    width: 20vw;
    height: 20vh;
    border-radius: 1vh;
    object-fit: cover;
    position: relative;
    top: 2vh;
}

.BotaoDeBaixo {
    width: 12vw;
}

.imagemSintaxe {
    width: 20vw;
    height: 20vh;
    border-radius: 1vh;
    object-fit: cover;
    position: relative;
    top: -2vh;
    left: 3vw;
}

.identificacaoArea {
    width: 33vw !important;
    font-size: 3.3vh;
    color: #323232;
}

.identificacaoArea::after {
    content: '';
    width: 20vw;
    height: 0.5vh;
    display: flex;
    border-radius: 0.7vh;
    background-color: #2a2a2a;
}

.PopupProfile-vzdna {
    position: absolute;
    z-index: 20000;
}

/*
Profile
*/

.Profile {
    max-width: 10vw;
    background-color: rgb(255, 255, 254);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10vh;
    border: none;
    display: flex;
    gap: 2vh;
    padding: 1vh 1.5vh;
    margin: 0 auto;
}

.Escala-subtitle {
    text-transform: uppercase;
}

.Escala-subtitle::after {
    content: '';
    display: flex;
    width: 3vw;
    border-radius: 0.7vh;
    height: 0.5vh;
    background: rgb(75, 131, 94);
    margin-bottom: 1vh;
}

.ProfilePicture {
    height: 3vh;
    overflow: hidden;
    object-fit: cover;
}

.text-description {
    font-size: small;
    text-align: justify;
}

.NomeUsuarioLogado {
    position: relative;
    top: 0.4vh;
    font-weight: 690;
}

.selecaoDeNiveis {
    position: relative;
    top: 0.8vh;
    width: 33vw;
    display: flex;
    justify-content: space-between;
}

.Escala {
    border: none;
    background-color: unset;
    border-radius: 0.5vh;
    border: 1px solid #1c1c1c;
    padding: 1vh 2vw;
    font-size: 1.7vh;
}

.select {
    background-color: #49d454 !important;
    color: #ffff !important;
}

.litle-img img {
    width: 17vw;
    object-fit: cover;
    height: 100%;
    border-radius: 1vh;
}

.sect-escala {
    position: relative;
    top: 4vh;
    display: flex;
    gap: 1vw;
    padding: 1vw;
    border: 1px solid #000;
    border-radius: 1.3vw;
    right: 3vw;
}

.section-header {
    background-color: #242424;
    display: flex;
    justify-content: space-around;
    gap: 1vw;
    max-width: 12vw;
    padding: 1vh 2vw;
    color: #ffff;
    border-radius: 0vw 0vw 0.5vw 0.5vw;
    position: absolute;
    right: 2vw;
}

.section-header img {
    width: 1.3vw;
    height: auto;
    object-fit: contain;
}

.usosDosoloAdapt {
    right: 0 !important;
    left: 0 !important;
    max-width: 70vw !important;
    height: 77.5vh;
    margin: 0 auto;
    flex-direction: column;
    border: none;
    border-radius: unset;
}

.usosDoSoloAdaptImg {
    width: 100%;
    display: flex;
    gap: 1vw;
    justify-content: space-between;
}

.usosDoSoloAdaptImg img {
    width: 90%;
    max-height: 35vh !important;
}

.ususDoSoloAdaptSelectNiveis {
    width: 50vw;
    position: relative;
    left: 2.5vw;
    top: 1.5vh;
}

.FirstRow .ftrow-title {
    max-width: 40vw;
    text-transform: uppercase;
    position: relative;
    top: 1.5vh;
    margin-bottom: 2vh;
    left: 2.5vw;
}

.Box-morfologia-imovel {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 80vh;
}

.img-dalle {
    width: 100%;
    margin-top: 3.5vh;
}

.img-dalle img {
    width: 50vw;
    display: flex;
    margin: 0 auto;
    border-radius: 1vw;
}

.descricao-morfologia {
    width: 70vw;
    height: 30vh;
    border: 1px solid #000;
    background-color: #ffffffa4;
    backdrop-filter: blur(0.5vh);
    border-radius: 1vw;
    position: relative;
    top: -10vh;
    left: 2vw;
    padding: 1.5vh;
}

.desc-title {
    background-color: #49d454;
    padding: 1vh 2vw;
    border-radius: 0.5vw;
    position: relative;
    top: -3vh;
    left: 1vw;
}

.ItemList img {
    width: 1.5vw;
    object-fit: contain;
}

.ItemList {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    margin-bottom: 1vh;
}

.flxColl {
    display: flex !important;
    flex-direction: column !important;
}

.container-descs-texts {
    width: 100%;
    display: flex;
    gap: 3vw;
    position: relative;
    top: 0.3vh;
}

.flxColl {
    text-align: justify;
}

.SintaxView {
    height: 70vh;
    background-color: #000;
}

.AnaliseIntegracao .titleSec {
    text-transform: uppercase;
    text-align: center;
}

.Popup {
    position: absolute;
    z-index: 120000;
    width: 30vw;
    height: 20vh;
    background-color: #ffffffce;
    backdrop-filter: blur(1vh);
}

.HeaderSetterAnalise {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ButtonsAnaliseLevels {
    position: relative;
    right: 10vw;
    top: -4vh;
}
.ButtonsAnaliseLevels h3{
    position: relative;
    right: -5vh;
    margin-bottom: 1vh;
}
.Separator {
    display: flex;
    width: 10vw;
    justify-content: space-between;
}

.ususDoSoloAdaptSelectNiveis button {
    background-color: #ffff;
    border: 1px solid #000;
    padding: 1vh 2vw;
}

.CantoSuperiorEsquerdo {
    width: 6.1vw !important;
    border-bottom: none !important;
    border-right: none;
    border-radius: 1vh 0vh 0vh 0vh;

    &:hover {
        background-color: #49d454;
    }
}

.CantoSuperiordDireito {
    width: 6.1vw !important;
    border-bottom: none !important;
    border-left: none !important;
    border-radius: 0vh 1vh 0vh 0vh;

    &:hover {
        background-color: #49d454;
    }
}

.BotaoDeBaixo {
    border-radius: 0vh 0vh 1vh 1vh;

    &:hover {
        background-color: #49d454;
    }
}

.SecaoUm_svg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Mapa_1_svg img {
    width: 35vw !important;
    height: 50vh;
    border-radius: 1vh;
    object-fit: cover;
}

.text-side-svg {
    width: 30vw;
    color: #000;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.secao_dois_mapa_text img {
    width: 35vw !important;
    height: 50vh;
    border-radius: 1vh;
    object-fit: cover;
}

.scd-svg-text {
    width: 100%;
    display: flex !important;
    align-items: center;
}

.EscolhaGlobak img {
    width: 50vw;
    height: 30vh;
}

.chartSection {
    justify-content: space-around;
}

.TitleSecaoDois {
    text-align: center;
    margin-top: 5vh;
    font-size: 3vh;
    text-transform: uppercase;
    color: #2a2a2a;
}

.TitleSecaoDois::after {
    margin-top: 1.5vh;
    position: relative;
    display: flex;
    margin: 0 auto;
    content: "";
    width: 25vw;
    height: 0.7vh;
    background: linear-gradient(45deg,
            #00ff00,
            #ffff00,
            #ff0000);
    border-radius: 1vh;
    margin-bottom: 1vh;
    margin-top: 2vh;
}

.adpat::after {
    content: '';
    background-color: unset;
    width: unset;
    height: unset;
    display: none;
}

.BoxEscolha-correlacao {
    width: 100%;
    padding: 1vw;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 0.6vw;
}

.secao-correlacoes {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    width: 70vw;
}

.title-BoxEscolha-correlacao {
    position: relative;
    top: -3.5vh;
    background-color: #44e251;
    padding: 0.7vh 2vw;
    border-radius: 0.5vh;
}

.secao-correlacoes {
    margin-top: 4.5vh;
}

.CardItemAnalise {
    max-width: 20vw;
    display: flex;
    flex-direction: column;
}

.body-BoxEscolha-correlacao {
    display: flex;
    justify-content: space-around;
}

.headerCard h3 {
    padding: 0.8vh 0vh;
    margin-bottom: 1vh;
    text-align: center;
    border-radius: 0.5vh;
    box-shadow: rgba(4, 95, 41, 0.02) 0px 1px 3px 0px, rgba(13, 80, 4, 0.15) 0px 0px 0px 1px;
}
.Body-card{
    text-align: justify;
}
.BoxConclusao-Sintaxe{
    box-sizing: border-box;
    border: 1px solid #08440a;
    border-radius: 1vh;
}
.BoxConclusao-Sintaxe{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 1.5vw;
    background-color: #bacfba;
}
.ConclusionItem{
    text-align: justify;
}
.title-conclusion-box{
    position: relative;
    top: -5vh;
    margin: 0 auto;
    background-color: #232323;
    padding: 1vh 2vw;
    color: #ffff;
    margin-bottom: -4vh;
    border-radius: 0.7vh;
}
.aplicacoes{
    display: flex;
    justify-content: space-around;
}
.title-box-aplicacoes{
    position: relative;
    top: -4vh;
    background-color: #44e251;
    padding: 0.7vh 2vw;
    border-radius: 0.5vh;
    max-width: 20vw;
    margin: 0 auto;
    text-align: center;
}
.BoxEscolha-correlacao{
    margin-top: 6vh;
}
.BoxHowToUse{
    max-width: 30vw;
    text-align: justify;
}
.chart-controll{
    width: 60vw;
}
.GraficosEMapa{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DescritivoDaareaa{
    max-width: 30vw;
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 3vh;
}
.map-control{
    width: 30vw;
    height: 10vh !important;
}
.Boz-page-usosDosolo{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
