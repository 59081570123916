.MeuPerfil {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.MeuPerfilBody {
    width: 74.5%;
    box-sizing: border-box;
}

.ProfileRow {
    background: rgb(2, 80, 49);
    background: -moz-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: -webkit-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#025031", endColorstr="#059846", GradientType=1);
    width: 100%;
    height: 20vh;
}
.ProfilePictue{
    position: relative;
    top: 13vh;
    left: 5vh;
    width: 13vh;
    height: 13vh;
    padding: 2vh;
    border-radius: 100%;
    object-fit: contain;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.InfoBody{
    position: relative;
    top: 12vh;
    height: 60vh;
    display: flex;
    flex-direction: column;
}
.DescricaoInfoBody{
    position: relative;
    top: -7vh;
    left: 39vh;
    padding: 2vh;
    text-transform: uppercase;
    font-weight: bold;
    color: #2c47306f;
}
.DataSect h2{
    font-family: 'Inter', Arial;
    font-size: 2.5vw;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    position: relative;
    top: -6.5vh;
}

.RedefinirSenhaButtonOpen{
    display: flex;
    margin: 0 auto;  
    padding: 1.4vh 3vw;
    border: none;
    background-color: #31b044;
    color: #fff;
    border-radius: 0.7vh;
    cursor: pointer;

    &:hover{
        background-color: #255b2d;
    }
}

.RedefinirSenhaPopup{
    width: 50vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    top: 5vh;
    background-color: #eaf3ea3b;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 1vh;
    padding: 2vw;
    justify-content: space-around;
}
.RedefinirSenhaPopup h2{
    text-align: center;
}
.Input-control-senha{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
.Input-control-senha input{
    padding: 1vh 2vw;
    background-color: #fff;
    border: 1px solid #1fb93b;
    border-radius: 0.5vh;
}
.RedefinirSenhaButtonSubmit{
    background-color: #31b044;
    border: none;
    width: 30vw;
    height: 4vh;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    border-radius: 0.5vh;

    &:hover{
        background-color: #255b2d;
    }
}