.map-report{
    width: 23vw;
    height: 95vh;
    margin: 0 auto;
}
.ReportPage{
    width: 100vw;
    display: flex;
    gap: 4vw;
    justify-content: space-between;
}
.Mapa-report-page{
    max-width: 25vw;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ImagensEIndicadores {
    width: 23vw;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    justify-content: center;
}
.imovel-raio-um-km,
.Imovel-report-page {
    display: flex;
    flex-direction: column;
    align-items: center; 
}
.imovel-raio-um-km img,
.Imovel-report-page img {
    width: 23vw;
    height: 25vh;
    object-fit: cover;
    border-radius: 0.7vw;
}
.imovel-raio-um-km img{
    position: relative;
    right: 2vw;
}

.Imovel-report-page img{
    position: relative;
    top: -1.4vh;
    left: 2vw;
}
.seta-corpo {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.seta-corpo::before {
    content: "";
    width: 0.5vh;
    height: 3vh;
    background-color: black;
    margin: 0px 0;
}
.seta {
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid black;
    margin-top: -1vh;
}
.Indicadores-report-page{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
.Indicadores-report-page a{
    text-decoration: none;
    color: #323131;
    background-color: #32ea25;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 0.5vh;
    border-radius: 0.4vh;
}
.body-info-report-page{
    height: 90%;

}
.report-page-body{
    width: 40vw;
    height: 100vh;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 0.5px 1px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 1vh 2vh;
} 
.All-rrepport-bboddy-for-header{
    display: flex;
    flex-direction: column;
}
.CabecalhoRelatorio {
    width: 100%;
    height: 8vh;
    display: flex;
    gap: 10%;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.LogoImg {
    height: 5vh;
    cursor: pointer;
    margin-left: 1.3vw;
}

.Acess {
    display: flex;
    align-items: center;
    gap: 1vh;
    margin-right: 1vw;
}

.Acess a {
    text-decoration: none;
    color: #1d1c1c;
    padding: 1vh 1.5vh;
}

.Acess a:hover {
    background-color: #34f21b;
    border-radius: 0.5vh;
}

.ReportSecaoInicial {
    width: 100%;
    box-sizing: border-box;
    padding: 4vh;
    display: flex;
    justify-content: space-between;
    gap: 4vh;
}

.indicadores-container {
    width: 100%;
    padding: 1vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.titulo-indicadores {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1vh;
}

.indicadores {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5vh;
    justify-content: center;
}

.GrupoIndicador {
    flex: 1 1 25%;
    max-height: 18vh; 
    padding: 1vh;
    background-color: #34f21b;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.GrupoIndicador h3 {
    font-size: 14px; 
    font-weight: bold;
    margin-bottom: 0.5vh;
    color: #12540a; 
}

.ItensGrupo {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 11px;
    line-height: 10px;  
    text-align: left;
}

.ItensGrupo li {
    margin-bottom: 0.3vh;  
    text-align: justify;
    color: #12540a;
}

.SecaoIndicadores {
    max-width: 50%;
    box-sizing: border-box;
    padding: 3vh;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 1vh;
}

.IndicadoresTitle {
    max-width: 35vh;
    position: relative;
    top: -5vh;
    background-color: #212121;
    padding: 0.7vh 2vh;
    color: #ffff;
    text-align: center;
    border-radius: 0.8vh;
}
.list-suboptions{
    display: flex;
    flex-direction: column;
    gap: 1vh;   
}
.list-suboptions a{
    background-color: #fafafa;
    font-size: 1.3vh;
    margin-left: 2vw;
    border-radius: 0.3vh;
}