.AtualizarRelatorio{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}
.OpcoesAtualização{
    width: 74vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 2vh;
    padding: 2vw;

}
.card-opcao-atualizacao{
    width: 13vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 1vh;
    cursor: pointer;
}
.secao-opcoes-gerais{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.opcoes-gerais{
    display: flex;
    flex-direction: column;
    gap: 4vh;
}
.greenDois{
    background-color: #169D58;
    color: #ffffff;

    &:hover{
        background-color: #15402E;
    }
}
.greenDois a{
    color: #ffffff;
}
.title-opcoes-gerais{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 2.7vh;
    height: 10vh;
    align-items: center;
}