.AdminGeral{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

.Body-admin-geral{
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 2vw;
}
.Dados-gerais-admn{
    display: flex;
    flex-direction: column;
}

.Dados-gerais-admn{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vh;

}
.Secao-de-dados-gerais{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

/* AdminNovoRelatorio */
.AdminNovoRelatorio{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}
.AdminNovoRelatorioBody{
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    gap: 1.5vh;
}
.sect-row{
    position: relative;
    left: 1.7vw;
    display: flex;
    flex-direction: column;

}
.sect-row-form-control{
    display: flex;
    flex-direction: column;
}
.sect-row-form-control input{ 
    padding: 1vh 4vh;
    border: 1px solid;
    border-radius: 0.4vh;
}
.sect-row-form-control textarea{
    min-width: 30vw;
    padding: 1vh 2vh;
    border: 1px solid;
    border-radius: 0.4vh;   
}

.input-holder{
    display: flex;
    gap: 1vw;
    flex-wrap: wrap;
}

.ChangeSection{
    display: flex;
    width: 10vw;
    height: 4vh;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    background-color: #2ca83f;
    border: none;
    cursor: pointer;
    border-radius: 1vh;
    font-size: 1.6vh;
    font-weight: 600;
    color: #fff;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.sect-row h3{
    text-align: center;
    position: relative;
    left: -1.7vw;
    margin-bottom: 2vh;
}

.data-geral {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    padding: 1vw;
    border-radius: 0.7vh;
    width: 20.5vw;
    background-color: #1f201f;
    color: #fff;
}
.green{
    background-color: #1f201f;
}
.Body-admin-actions{
    display: flex;
    flex-direction: column;
    gap: 3vh;
    margin-top: 2vh;
}
.actions-bdy{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.admin-action a{
    text-decoration: none;
    color: #114711;
    &:hover{
        color: #fff;
    }
    
}
.admin-action{
    width: 12vw;
    min-height: 5vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0vh 1.5vw;
    background-color: #64df6c;
    border-radius: 0.7vh;
    cursor: pointer;
    color: #fff !important;

    &:hover{
        background-color: #1f201f;
    }
    
}
.InitReport{
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.flex-escala-newReport{
    display: flex;
    flex-direction: column;
}

.Escala-new-report{
    display: flex;
    flex-direction: column;
}

.form-control-new-report{
    display: flex;
    flex-direction: column;
}

.RetomarDna{
    display: flex;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    max-height: 28.6vh;
    border-radius: 1vh;
}
.ImagemMacroEscala{
    width: 15vw;
    border-radius: 1vh 1vh 0vh 0vh;
}
.cabecalho-retomar-dna{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cabecalho-retomar-dna span{
    padding: 0.7vh 1.5vh;

}

.Continuar{
    margin-top: -0.7vh;
    border: none;
    background-color: #64df6c;
    padding: 1vh;
    border-radius: 0vh 0vh 1vh 1vh;
}

.InitReport{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
.Escala-new-report{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
.flex-escala-newReport{
    border: 1px solid #000;
    padding: 1.5vw;
    border-radius: 1vh;
}
.form-control-new-report{
    display: flex;
    flex-direction: column;
    padding: 1vh;
}
.form-control-new-report label{
    font-weight: bold;
    &::after{
        content: '';
        display: flex;
        width: 5vw;
        height: 4px;
        background-color: #2ca83f;
        border-radius: 1vh;
        margin-top: 0.3vh;
        margin-bottom: 2vh;
    }
}
.form-control-new-report textarea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh;
}

.AdminNovoRelatorioBody h2{
    text-align: center;
    font-size: 4vh;
}
.InitReport h2 {
    font-size: 2vh;
    background-color: #64df6c;
    max-width: 12vw;
    height: 4vh;
    margin: 0 auto;
    width: 100%;
    border-radius: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    bottom: -3vh;
}
.NovoRelatorioAdminButton{
    width: 40vw;
    height: 5vh;
    margin: 0 auto;
    margin-top: 4vh;
    margin-bottom: 4vh;
    background-color: #2ca83f;
    border: none;
    border-radius: 1vh;
    color: #fff;
    cursor: pointer;
    &:hover{
        background-color: #fff;
        color: #2ca83f;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
}
.RelatoriosPendentes{
    display: flex;
    flex-wrap: wrap;
    gap: 1vh;
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: 5vh;
}