.Login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;

}
.LateralEsquerda {
    width: 30%;
    height: 100vh;
    background: rgb(2, 80, 49);
    background: -moz-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: -webkit-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#025031", endColorstr="#059846", GradientType=1);
    display: flex;
    flex-direction: column;
    gap: 2vh;
    justify-content: center;
}
.Titlelogin{
    position: relative;
    left: 10%;
    color: rgb(238, 244, 239);
    font-size: 6vh;
    text-align: left;
    text-transform: uppercase;
    max-width: 80%;
    font-weight: 1900;
    font-family: Impact, Haettenschweiler;
}
.DescriptionLogin{
    position: relative;
    left: 10%;
    color: rgb(238, 244, 239);
    font-size: 2vh;
    text-align: justify;
    max-width: 80%;
}

.LateralDireita{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.formularioLogin{
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}
.formControll{
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}
.formControll input{
    padding: 1.3vh;
    width: 45vh;
    border: none;
    border-radius: 0.5vh;
    box-shadow: #9f9e9e29 0px 1px 4px;
}
.formControll label{
    color: #0e4520;
    font-family: arial;
    font-size: 2vh;
    font-weight: bold;
    
}
.LogoVizzion{
    position: absolute;
    top: 4vh;
    right: 4vh;
    width: 10%;
}
.Greeting{
    position: relative;
    bottom: 7vh;
    margin: 0 auto;
    font-family: impact;
    font-size: 5vh;
    text-transform: uppercase;
    color: #0e4520;
}

.formSubmitControll input[type="submit"]{
    position: relative;
    top: 2vh;
    width: 100%;
    padding: 1vh;
    background-color: #1bc052;
    border: none;
    border-radius: 0.4vh;
    font-family: arial;
    font-size: 2vh;
    color: #0e4520;
    color: #fff;
    cursor: pointer;
    &:hover{
        background-color: #0e4520;
    }
}
.Login .Message{
    position: absolute;
    top: 4vh;
    left: 59vh;
    background-color: #e0e5e2;
    padding: 2vh 5vh;
    border-radius: 1vh;
    color: #0e4520;
    border-left: 5px solid #0e4520;
    animation: fadeOutBorder 5s forwards;
    font-size: 14px;
}

@keyframes fadeOutBorder {
    0% {
        border-left-width: 5px;
    }
    100% {
        border-left-width: 0;
    }
}