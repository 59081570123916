.portal-not-found-page{
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.img-not-found{
    width: 20vw;
    margin: 0 auto;
}
.img-not-found img{
    width: 100%;
    display: flex;
    margin: o auto;
}
.text-not-found{
    max-width: 40vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.text-not-found{
    text-align: center;
}
.text-not-found h1{
    font-size: 4vw;
}
.text-not-found a{
    text-decoration: none;
    background-color: #34f21b;
    padding: 1vw 2vw;
    border-radius: 2vh;
    color: #0a4e38;
    &:hover{
        background-color: #1b723c;
        color: #ffff;
    }
}