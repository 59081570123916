.SecaoLateral {
    max-width: 44vh;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0vh 2vh;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.SecaoLateral .Profile {
    max-width: 36%;
    background-color: rgb(255, 255, 254);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10vh;
    border: none;
    display: flex;
    gap: 2vh;
    padding: 1vh 1.5vh;
    margin: 0 auto;
    margin-right: 3vh;
    margin-top: 2vh;
}

.ProfilePicture {
    height: 3vh;
    overflow: hidden;
    object-fit: cover;
}

.TitleSecaoLateral {
    position: relative;
    top: 3vh;
    left: 2vh;

    &::after {
        content: "";
        display: flex;
        width: 12vh;
        height: 5px;
        background-color: rgb(17, 149, 53);
        border-radius: 1vh;
        margin-top: 1vh;
    }
}

.CorpoDaSecaoLateral {
    position: relative;
    z-index: 100;
}

.VetoresBanner {
    position: absolute;
    z-index: 00;
    height: 100%;
    width: 43vh;
    object-fit: cover;
    overflow: hidden;
    left: 5vh;
    bottom: 0;
}

.LogoDaSecaoLateral {
    position: relative;
    top: -4vh;
    z-index: 1000;
    display: flex;
    margin: 0 auto;
    height: 4vh;
}

.ButtonsSections {
    width: 90%;
    margin-top: 6vh;
    margin-left: 2vh;
    display: flex;
    justify-content: space-between;
    gap: 3vh;
    flex-wrap: wrap;
}

.Botao {
    text-decoration: none;
    width: 16vh;
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 1vh;
    padding: 1vh;
}

.Icone {
    position: relative;
    height: 5vh;
    width: 5vh;
    object-fit: contain;
}

.NomeUsuarioLogado {
    position: relative;
    top: 0.4vh;
    font-weight: 690;
}

.escuro {
    background-color: #2D2D2D;
    color: #59F873;

    &:hover {
        background-color: #59F873;
        color: #2D2D2D;
    }
}

.claro {
    background-color: #59F873;
    color: #2D2D2D;

    &:hover {
        background-color: #2D2D2D;
        color: #59F873;
    }
}

.BodyDashboard {
    width: 74%;

}

.PopupProfile {
    position: absolute;
    width: 28vh;
    z-index: 10000;
    background-color: #56d01db8;
    padding: 3vh 0vh;
    border-radius: 0vh 2vh 2vh 2vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    left: 30vh;
    top: 7vh;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.listOfProfileOptions {
    list-style-type: none;
    height: 26vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.itemPopup {
    padding-top: 2.1vh;
    padding-bottom: 2.1vh;
    width: 90%;
    margin: 0 auto;

    a {
        text-decoration: none;
        color: #272626;
    }
}

.itemPopup:hover {
    text-align: center;
    width: 100%;
    background-color: #272626d3;
    a{
        color: #ffff !important;
    }
}

.Sair {
    padding-top: 2.1vh;
    padding-bottom: 2.1vh;
    width: 90%;
    margin: 0 auto;

    a {
        text-decoration: none;
        color: #272626;
    }
}

.Sair:hover {
    text-align: center;
    width: 100%;
    background-color: #000000d3;
    a{
        text-transform: uppercase;
        font-weight: bold;
        color: #ffffff !important;
    }
}