.MeusRelatorios {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;

}

.MeusRelatoriosBody {
    width: 74%;
    max-height: 98vh;
    padding: 3vh;
}

.Tittle-meus-relatoriis {
    text-transform: uppercase;
    position: relative;
    display: flex;
    gap: 2vh;
    align-items: center;
    text-transform: uppercase;
    color: #1d1d1d !important;
}

.demanda-card {
    width: 30vh;
    height: 18.5vh;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 1vh 2.5vh;
    border-radius: 1vh;
}

.titulo-demanda-card {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2vh;
    display: flex;
    flex-direction: column;

    &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #47ec58;
        margin-bottom: 1vh;
        margin-top: 0.7vh;
    }
}

.rowDemandas {
    position: relative;
    top: 3vh;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 3vh;
    max-height: 85vh;
    overflow-y: auto;
    padding: 1vh;
    box-sizing: border-box;
}

.ButtonsAcessReport {
    margin-top: 2vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ButtonsAcessReport a {

    width: 40%;
    background-color: #1c1c1c;
    text-decoration: none;
    color: #fff;
    padding: 1vh;
    border-radius: 0.7vh;
    &:hover{
        background-color: #47ec58;
        color: #1d1d1d;
    }
}