@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

@import "./styles/Home.css";
@import "./styles/VizzionDna.css";
@import "./styles/StyleVizzionInsights.css";
@import "./styles/Dashboard.css";
@import "./styles/Login.css";
@import "./styles/MeuPerfil.css";
@import "./styles/MeusRelatorios.css";
@import "./styles/Report.css";
@import "./styles/SolicitacaoRelatorio.css";
@import "./styles/VizzionDna.css";
@import "./styles/StyleVizzionInsights.css";
@import "./styles/SecaoLateral.css";
@import "./styles/MapStyle.css";
@import "./styles/NotFound.css";        
@import "./styles/NovoFormatoRelatorio.css";
@import "./styles/Loading.css";

@import "./styles/AdminGeral.css";
@import "./styles/PaginaDeNovoUsuario.css";
@import "./styles/RetomarRelatorio.css";
@import "./styles/AtualizacaoRelatorio.css";
@import "./styles/Atualizar-relatorio.css";