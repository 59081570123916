.map-container {
    height: 60vh;
    width: 40vw;
    border-radius: 15px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 900px) {
    .map-container {
      width: 90vw;
    }
  }

.map-report{
  position: relative;
  top: 3vh;
  width: 95%;
  height: 35vh;
  border-radius: 1vh;
  z-index: 00;
}