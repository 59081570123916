.VizzionInsights-page {
    width: 100vw;
}

.Insights-body {
    display: flex;
    flex-direction: column;
}

.Banner-insigths {
    width: 100vw;
    height: 70vh;
    background: rgb(2, 80, 49);
    background: -moz-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: -webkit-linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    background: linear-gradient(128deg, rgba(2, 80, 49, 1) 2%, rgba(23, 144, 77, 1) 16%, rgba(16, 121, 84, 1) 28%, rgba(16, 121, 84, 1) 29%, rgba(13, 92, 64, 1) 39%, rgba(4, 75, 53, 1) 70%, rgba(5, 152, 70, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#025031", endColorstr="#059846", GradientType=1);
}

.Names {
    margin: 0 auto;
    width: 90vw;
    display: flex;
    justify-content: space-around;
    color: #ffff;
    text-transform: uppercase;
    position: relative;
    top: 17vh;
}

.BackBanner {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 70vh;
    object-fit: cover;
    opacity: 1vw;
}

.Contato-representante {
    position: relative;
    top: 7vh;
    padding: 2vh 4vw;
    text-decoration: none;
    color: #118240;
    background-color: #ffffffdb;
    border-radius: 0.7vw;
}

.Info-insights {
    margin: 0 auto;
    width: 95vw;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10vh;
}

.Box {
    display: flex;
    flex-direction: column;
    width: 10vw;
    height: 10vh;
    border-radius: 1vh;
    border: 1px solid #118240;
    text-align: center;
    padding: 2vw;
    justify-content: center;
    gap: 1vw;
}

.Box img {
    margin: 0 auto;
    width: 3vw;

}

.Banner-insigths h1 {
    color: #ffff;
    font-size: 5vw;
    line-height: 11vh;
    font-family: inter, Arial !important;
    font-weight: 900;
    line-height: 9.7vh !important;
}

.contents-insigth {
    position: relative;
    width: 90%;
    top: 20vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Area-mapeada {
    margin: 0 auto;
    width: 90vw;
    height: 70vh;
    display: flex;
    justify-content: space-between;
    gap: 5vw;
}
.FAQ{
    display: none !important;
}

.ImoveisMonitorados {
    width: 22vw;
    height: 2vh;
    padding: 1.5vh;
    padding-bottom: 2vh;
    text-align: center;
    align-items: center;
    background: rgb(64, 203, 85);
    border-radius: 0.6vw;
}

.Vendas {
    text-decoration: none;
    background-color: #118240;
    color: #ffff;
    text-align: center;
    padding: 1vw;
    border-radius: 0.5vw;
}

.Text-area-mapeada {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Titulo-area-mapeada {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.Titulo-area-mapeada h1 {
    color: #262626;
    font-size: 5vw;
    line-height: 11vh;
    font-family: inter, Arial !important;
    font-weight: 900;
    line-height: 9.7vh !important;
}

.Titulo-area-mapeada p {
    text-align: justify;
}

.Boxes {
    display: flex;
    gap: 3vh;
}

.OqueMapeamos {
    width: 90vw;
    margin: 0 auto;
    text-align: center;
    font-size: 2.5vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    color: #262626;
    font-family: Arial;
    font-weight: 950;
}

.mb {
    margin-bottom: 5vh !important;
}

.insights-tttx {
    line-height: 5vh !important;
}

.Analises-exclusivas {
    width: 90vw;
    margin: 0 auto;
    height: 120vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.Texts-analises-exclusivas {
    text-align: center;
}

.Texts-analises-exclusivas h2 {
    color: #2b2a2a;
    font-size: 5vw;
    line-height: 11vh;
    font-family: inter, Arial !important;
    font-weight: 900;
    line-height: 9.7vh !important;
}

.Texts-analises-exclusivas p {
    font-size: 1.5vw;
}

.Infos {
    display: flex;
    gap: 9vh;
    justify-content: space-between;
}

.Card-info {
    width: 30vw;
    padding: 2vw;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    border-radius: 1vh;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.Analises {
    display: flex;
    gap: 1vw;
    align-items: center !important;
}

.Analises img {
    width: 5vw;
}

.Analises h3 {
    font-size: 1.7vw;
    line-height: 3.5vh;
}

.Descricao-card-info {
    text-align: justify;
}

.info-section {
    display: flex;
    flex-direction: column;
    gap: 8vh;
}

.Plans-and-pricing {
    margin: 0 auto;
    width: 90vw;
    height: 80vh;
}

.FAQ {
    padding: 2vw;
    width: 90vw;
    margin: 0 auto;
    height: 70vh;
}

.plans-boxes {
    display: flex;
}

.plans-boxes {
    margin: 0 auto;
    width: 65vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.analises-plans {
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: space-around;
    padding: 2vw;
    border-radius: 1vw;
    background-color: #3dc454;
    color: #ffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.AnalisesDisp {
    display: flex;
    gap: 1vh;
    align-items: center;
}

.alertas {
    display: flex;
    flex-direction: column;
}

.Escolhas-title {
    width: 20vw;
    height: 10vh;
    line-height: 4vh;
}

.Price {
    display: flex;
    font-weight: 900;
}

.Price .sinalDosPila {
    font-size: 1vw;
}

.Price .Indicativo-mes {
    font-size: 1vw;
}

.Price p {
    font-size: 4vw;
}

.Alertas-inclusos {
    display: flex;
    flex-direction: column;
}

.Line::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #262626;
    margin: 10px 0;
}

.EnviarMensagemConsultor {
    padding: 1vh 10vw;
    margin-top: 3vh;
    border: none;
    background-color: #3dc454;
    border-radius: 0.4vw;
    color: #ffff;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background-color: #118240;
    }
}

.plans-title {
    text-align: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.plans-title h1 {
    color: #2b2a2a;
    font-size: 3vw;
    line-height: 11vh;
    font-family: inter, Arial !important;
    font-weight: 900;
    line-height: 9.7vh !important;
}

.FAQ {
    display: flex;
    flex-direction: column;
}

.faq-item {
    display: flex;
    flex-direction: column;
}

.text-faq-item {
    width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;
}

.descricao-faq-item {
    width: 80vw;
    text-align: justify;
}

.Triangle {
    border-top: 3vw solid transparent;
    border-bottom: 3vw solid transparent;
    border-left: 25px solid #36d04d;
    border-radius: 1vw;
}



@media screen and (max-width: 900px) {
    .OqueMapeamos {
        font-size: 8vw;
        margin-bottom: unset;
        margin-top: 4vh;
        line-height: 5.5vh;
    }

    .Names {
        display: none;
    }

    .Texts-insights h1 {
        font-size: 12vw !important;
        line-height: 6vh !important;
    }

    .Info-insights {
        width: 100vw;
        flex-wrap: wrap;
        margin-top: 4vh;
        margin-bottom: 4vh;
        flex-direction: column;
        gap: 3vh;
    }

    .Box {
        width: 20vw;
        font-size: 2vw;
    }

    .Box img {
        width: 7vw;
    }

    .Boxes {
        margin: 0 auto;
    }

    .Area-mapeada {
        flex-direction: column;
    }

    .Text-area-mapeada {
        width: 90vw;
        height: 45vh;
    }

    .ImoveisMonitorados {
        margin: 0 auto;
        text-align: center;
        width: 70vw;
        text-align: center;
        padding: 1vh;
    }

    .Titulo-area-mapeada h1 {
        font-size: 10vw;
        line-height: 5vh !important;
    }

    .Titulo-area-mapeada p {
        font-size: 4vw;
    }

    .Vendas {
        padding: 2.5vh 1.5vh;
        align-items: center !important;
        font-size: 4vw;
        border-radius: 2vw;
    }

    .Area-mapeada {
        height: 110vh;
        margin-bottom: 10vh;
    }

    .Analises-exclusivas {
        height: 330vh;
    }

    .Infos {
        flex-direction: column;
    }

    .Card-info {
        margin: 0 auto;
        width: 80vw;
    }

    .Texts-analises-exclusivas h2 {
        font-size: 11vw !important;
        line-height: 5vh !important;
    }

    .Texts-analises-exclusivas p {
        font-size: 4vw;
        max-width: 80vw;
        margin: 0 auto;
        margin-top: 2vh;
        text-align: justify;
    }

    .Analises {
        flex-direction: column;
    }

    .Analises img {
        width: 15vw;
    }

    .Analises h3 {
        font-size: 7vw;
        text-align: center;
    }

    .Descricao-card-info {
        font-size: 4vw;
        padding: 2vw;
    }

    .Plans-and-pricing {
        flex-direction: column;
        height: 145vh;
    }

    .plans-title {
        display: flex;
        flex-direction: column;
    }

    .plans-title h1 {
        font-size: 11vw !important;
        line-height: 5vh !important;
    }

    .plans-title p {
        font-size: 4vw;
        margin-top: 2vh;
        text-align: justify;
    }

    .plans-boxes {
        flex-direction: column !important;
    }

    .Analises-cards-plans {
        width: 90vw;
        font-size: 4vw;
    }

    .Analises-cards-plans input {
        padding: 2vw;
    }

    .Analises-cards-plans input[type="checkbox"] {
        width: 5vw !important;
        height: 5vh !important;
    }

    .Escolhas-e-infos {
        width: 90vw !important;
        margin-top: 5vh;
    }

    .Escolhas-title {
        width: 90vw;
        text-align: center;
        font-size: 11vw;
    }

    .Price {
        width: 40vw !important;
        position: relative;
        left: 30%;
        margin-bottom: 3vh;
    }

    .sinalDosPila {
        font-size: 4vw !important;
    }

    .Indicativo-mes {
        font-size: 4vw !important;
    }

    .Price p {
        font-size: 11vw;
    }

    .Alertas-inclusos h2 {
        font-size: 7vw;
        text-align: center;
    }

    .Alertas-body {
        font-size: 5vw;
        margin-top: 2vh;
    }

    .EnviarMensagemConsultor {
        width: 90vw;
        height: 5vh;
        font-size: 4vw;
    }

    .FAQ {
        margin-top: 2vh;
    }
}