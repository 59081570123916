.VizzionDnaBody{
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.dna-title{
    width: 80vw;
    font-size: 4vw !important;
    font-family: inter, Arial !important;
    font-weight: 900;
    line-height: 7.5vh !important;
}

.dna-subtitle{
    max-width: 80vw !important;
}

@media screen and (max-width: 790px) {
    .dna-title{
        margin-top: 22vh;
    }
    .dna-title{
        font-size: 13vw !important;
        line-height: 6vh !important;
    }
}
