.Dashboard {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.BodyDashboard {
    max-height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AlertsAndDos {
    display: flex;
    justify-content: space-between;
}

.Todos {
    margin-left: 5vh;
    position: relative;
    top: 5vh;
    width: 50%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Alertas {
    margin: 2vh;
    width: 40%;
    min-height: 57vh;
    /* background-color: rgb(227, 237, 230); */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 1vh;
    padding: 1vh;
}

.ButtonToDo {
    width: 15vh;
    height: 15vh;
    box-sizing: border-box;
    background: #2b2b2b;
    color: #ffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5vh;
    padding: 1vh;
    font-family: Arial;
    text-transform: uppercase;
    border: none;
    border-radius: 1vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        background-color: #4cec67;
            color: #2b2b2b;
    }
}
.removeBackground{
    background-color: unset !important;
}

.buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Atalhos{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25vh;
}

.TitleTodos{
    text-transform: uppercase;
    color: #2b2b2b;
    font-size: 2.7vh;
    font-weight: bold;   
}

.IndicesMonitoramento{
    height: 17.4vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.row{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.indicador{
    width: 44%;
    background-color: #4af666;
    padding: 1.5vh;
    border-radius: 0.7vh;
    color: #0a4628;
}
.Subtitle{
    text-align: center;
    margin-bottom: -2.5vh;
}


.AlertComponent{
    width: 100%;
    height: 100%;
}

.AlertCompBody{
    display: flex;
    width: 90%;
    justify-content: space-between;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1vh;
    border-radius: 0.9vh;
    background-color: #fff;
    margin: 0 auto;
}
.BaixaImportancia{
    width: 7vh;
    height: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-around !important;
    background-color: #59d318;
    border-radius: 0.5vh;
}
.Imagem{
    display: flex;
    margin: 0 auto;
    width: 3vh !important;
    height: auto;

}
.MediaImportancia{
    width: 7vh;
    height: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-around !important;
    background-color: #e4920f;
    border-radius: 0.5vh;
}
.altaImportancia{
    width: 7vh;
    height: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-around !important;
    background-color: #e40f0f;
    border-radius: 0.5vh;
}

.texts{
    display: flex;
    flex-direction: column;
}
.descricao{
    max-width: 28vh;
    text-align: justify;
    letter-spacing: 0.0001vh;
}
.ButtonAlert{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 8vh;
    background-color: #2b2b2b;
    border: none;
    border-radius: 0.8vh;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 0.9vh;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
}

.BodyAlert{
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: scroll;
    gap: 2vh;
}
.AlertComponentHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh;
}