.Acoes-importantes{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2vh;
}

.Acoes-importantes a{
    text-decoration: none;
    color: #ffffff;
    background-color: #34ab44;
    padding: 1vh 2vw;
    border-radius: 0.7vh;
}
.turnPublic{
    background-color: #ffc401;
    border: none;
    padding: 1vh 2vw;
    color: #ffffff;
    border-radius: 0.7vh;
    cursor: pointer;
    &:hover{
        background-color: #d65200;
    }
}