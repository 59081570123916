.RelatoriosPendentesOuIniciados{
    width: 100vw;
    display: flex;
    justify-content: space-between;
}
.body-RelatoriosPendentesOuIniciados{
    width: 74vw;
    padding: 2vw;
}
.Header-RelatoriosPendentes{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.RelatoriosPendentes{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 70vh;
    margin-top: 2vh;
    margin: 0 auto;
    gap: 1vw;
}
.nothingHere{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 50%;
}